import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Api } from 'src/app/configs/config';
import { LookupEnums } from 'src/app/shared/models/lookupEnum';


/**
 * Enum Service provides cached (loaded once) access to server side enum values
 * */
@Injectable({
	providedIn: 'root'
})
export class EnumService {

	enums: BehaviorSubject<LookupEnums> = new BehaviorSubject<LookupEnums>({
		Country: [],
		GeographicScopeType: [],
		JobTitle: [],
		OrganisationType: [],
		Platform: [],
		ShareWithGovernmentConsentType: [],
		Roles: [],

		ChargingPolicy: [],
		Condition: [],
		FoodAndBeverageService: [],
		Status: [],

		TransportFlag: [],
		SportServicesFlag: [],
		OtherServicesFlag: [],
		FeaturesFlag: [],

		UsageMonthFlag: [],
		UsageLevel: [],


		Funding: [],
		ActionPlanType: [],
		Progress: [],
		ProjectPriority: [],
		ActionPlanVisibility: [],

		InputType: [],
		NumericOperator: [],

		CompetitionCompliance: [],
		HireAgreement: [],
		OwnerType: [],

		Accessibility: [],
		CoLocationFlag: [],
		FeeSubsidyFlag: [],

		NidFacilitySettingInd: [],
		NidFacilitySpaceTypeInd: [],
		NidFacilitySurfaceTypeInd: [],
		NidFacilityUsageTypeInd: []
	});

	constructor(private http: HttpClient) {
		this.getEnums();
	}

	/**
	 * Retrieve the enums from our API
	 * */
	private getEnums() {
		// TODO: Handle situation where this fails (perhaps implement retry)
		this.http.get<LookupEnums>(Api.getEnumsUrl).subscribe((resp: LookupEnums) => {
			this.enums.next(resp);
		});
	}
}
