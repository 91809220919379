import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

	@Input() iconName: string = '';
	@Input() iconOutlined: boolean = true;
	@Input() notificationText: string = '';
	@Input() callToActionText: string = '';
	@Input() compact: boolean = false;
}
