import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapService } from 'src/app/core/services/maps/map.service';
import { SidebarMessaging } from 'src/app/core/services/maps/sidebarMessage';
import { NIDShowSiteModalOptions, NID_TAB_NAMES } from 'src/app/core/services/nid-modal-service';
import { NIDActionPlanViewComponent } from './components/view/action-plan-view/action-plan-view.component';
import { NIDFacilityViewComponent } from './components/view/facility-view/facility-view.component';
import { NIDSiteViewComponent } from './components/view/site-view/site-view.component';
import { MovementMonitoringType, SharingLevel } from 'src/app/shared/models/movement';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
    selector: 'app-nid-modal',
    templateUrl: './nid-modal.component.html',
    styleUrls: ['./nid-modal.component.scss'],
})
export class NIDModalComponent implements OnInit {
    @ViewChild(NIDSiteViewComponent) siteViewComp!: NIDSiteViewComponent;
    @ViewChild(NIDFacilityViewComponent) facilityViewComp!: NIDFacilityViewComponent;
    @ViewChild(NIDActionPlanViewComponent) actionPlanViewComp!: NIDActionPlanViewComponent;

    @Output() newSiteCreated = new EventEmitter<number>();

    tabs: string[] = ['Site', 'Facilities', 'Action Plans'];

    modalState: NIDSiteModalState;

    lastUpdateOn: any;
    canMonitorMovement: boolean = false;

    constructor(
        private mapService: MapService,
        private dialogService: MatDialog,
        private authService: AuthService,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public modalOptions: NIDShowSiteModalOptions
    ) {}

    ngOnInit(): void {
        if (this.modalOptions.siteId == 0) {
            this.modalState = new NIDSiteModalState(this.modalOptions.siteId, 'Site');
            this.modalState.newSiteLatitude = this.modalOptions.newLat;
            this.modalState.newSiteLongitude = this.modalOptions.newLong;
            this.modalState.siteName = 'Create New Site';
            this.modalState.editMode = true;

            if (this.modalOptions.showGeographicControls)
                this.modalState.showGeographicControls = this.modalOptions.showGeographicControls;
        } else {
            this.modalState = new NIDSiteModalState(this.modalOptions.siteId, this.modalOptions.tabName);
            if (this.modalOptions.showGeographicControls)
                this.modalState.showGeographicControls = this.modalOptions.showGeographicControls;
        }

        this.canMonitorMovement = this.mapService.mapStartupData.movementProFeatures;
    }

    changeTab(tabName: string) {
        this.modalState.currentTab = tabName as NID_TAB_NAMES;
    }

    /** Edit the currently shown record */
    editRecord() {
        if (this.modalState.userCanEditSite) {
            this.modalState.editMode = true;
        } else {
            this.messageService.showError(
                'Please contact the Site Owner/Operator or ActiveXchange Support to request changes.'
            );
        }
    }

    /** Exit edit mode for the current record */
    editRecordCloseEvent(eventData: NIDRecordEditCloseEvent) {
        this.modalState.editMode = false;

        if (eventData.recordWasUpdated) {
            switch (this.modalState.currentTab) {
                case 'Site':
                    if (this.modalState.siteId == 0) {
                        this.newSiteCreated.emit(eventData.recordId);
                    }
                    this.modalState.siteId = eventData.recordId;
                    this.siteViewComp.loadSite();
                    break;

                case 'Facilities':
                    this.modalState.selectedFacilityId = eventData.recordId;
                    this.facilityViewComp.loadSiteFacilities();
                    break;

                case 'Action Plans':
                    this.modalState.selectedActionPlanId = eventData.recordId;
                    this.actionPlanViewComp.loadSiteActionPlans();
                    break;
            }
        } else {
            if (this.modalState.siteId == 0) {
                this.dialogService.closeAll();
            }
        }
    }

    /** Click Handler for movement button */
    monitorForMovement() {
        this.mapService.sendMessageToSidebar({
            messageType: SidebarMessaging.SidebarMessageTypeInd.ShowMovementMonitoringModal,
            messageData: {
                item: {
                    id: this.modalState.siteId,
                    name: this.modalState.siteName,
                    sharingLevel: SharingLevel.Organisation,
                    type: MovementMonitoringType.MonitoredNidSite,
                    readOnly: false,
                    isCreator: true,
                    organisationId: this.authService.getUserOrganisationId(),
                    teams: [],
                },
                isDirty: true,
                isNew: true,
            },
        });
    }

    updateDate(e: any) {
        this.lastUpdateOn = e;
    }
}

export class NIDSiteModalState {
    siteId: number = -1;
    siteName: string = 'Loading';
    editMode: boolean = false;
    currentTab: NID_TAB_NAMES = 'Site';
    showGeographicControls: boolean = false;

    selectedFacilityId: number = -1;
    selectedActionPlanId: number = -1;

    // only set when creating a new site
    newSiteLatitude: number;
    newSiteLongitude: number;

    // permissions
    userCanEditSite: boolean = false;

    constructor(siteId: number, tabName?: NID_TAB_NAMES) {
        this.siteId = siteId;
        if (tabName) this.currentTab = tabName;

        if (this.siteId == 0) {
            this.currentTab = 'Site';
            this.editMode = true;
        }
    }
}

/**
 * Represents the event raised by an edit component back to the NID Modal Component
 */
export interface NIDRecordEditCloseEvent {
    recordWasUpdated: boolean;
    recordId: number;
}
