import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ReportsService } from 'src/app/core/services/reports/reports.service';
import { saveAs } from 'file-saver';
import BaseComponent from 'src/app/core/base/base-component.component';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.scss'],
})
export class ReportsListComponent extends BaseComponent implements OnInit {
    organisationId: any = 0;
    reports: any = [];
    totalReports: number = 0;

    constructor(private reportsService: ReportsService, private authService: AuthService) {
        super();
    }

    ngOnInit(): void {
        // get user organisation Id
        this.organisationId = this.authService.getUserInfo().organisationId;
        if (this.organisationId > 0) this.getOrganisationReports(this.organisationId);
    }

    getOrganisationReports(orgId: any) {
        this.loading = true;

        this.reportsService
            .getOrganisationReports(orgId)
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((data) => {
                this.loading = true;
                if (data) {
                    this.reports = data;
                    this.loading = false;
                    this.totalReports = data.length;
                }
            });
    }

    setFileIcon(val: string) {
        let fileExt = val.split('.').pop();
        if (['xls', 'xlsx'].includes(fileExt)) {
            return 'assets/images/reports/xls.svg';
        } else if (['doc', 'docx'].includes(fileExt)) {
            return 'assets/images/reports/doc.svg';
        } else if (['ppt', 'pptx'].includes(fileExt)) {
            return 'assets/images/reports/ppt.svg';
        } else if (['pdf'].includes(fileExt)) {
            return 'assets/images/reports/pdf.svg';
        }
    }

    fetchReportById(item: any) {
        if (item.webUrl != '') {
            window.open(item.webUrl, '_blank');
        } else {
            this.loading = true;
            item.isLoading = true;

            this.reportsService
                .fetchOrganisationReport(item.id)
                .pipe(takeUntil(this.subscriptionComplete))
                .pipe(
                    finalize(() => {
                        this.loading = false;
                        item.isLoading = false;
                    })
                )
                .subscribe((data) => {
                    if (data) {
                        this.arraytoFile(data, item.fileName, item.mimeType);
                    }
                });
        }
    }

    arraytoFile(data, fileName: string, contentType: string) {
        var sliceSize = 512;
        var byteCharacters = atob(data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);

            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var file = new File(byteArrays, fileName, { type: contentType });

        saveAs(new Blob(byteArrays, { type: contentType }), fileName);
        return file;
    }
}
