<div class="button-bar">
	<app-icon-button icon="cancel" text="CANCEL" size="small" (buttonClicked)="cancel()" color="grey"></app-icon-button>
	<app-icon-button icon="check_circle" text="SAVE" size="small" (buttonClicked)="submitForm()"></app-icon-button>
</div>

<div style="padding: 15px">
	<form [formGroup]="form" (ngSubmit)="submitForm()" class="container-fluid">

		<div class="row">
			<div class="col-12 col-md-9">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Action Plan Name</mat-label>
					<input matInput formControlName="name" required>
					<mat-error>
						Please enter Action plan name
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-12 col-md-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Type</mat-label>
					<mat-select name="typeInd" formControlName="typeInd">
						<mat-option *ngFor="let v of actionPlanTypes" [value]="v.id">{{v.name}}</mat-option>
					</mat-select>
					<mat-error>Please select Action Plan type</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-12" *ngIf="form.value.typeInd === 9">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Other Type</mat-label>
					<input matInput formControlName="typeOther">
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Overview</mat-label>
					<textarea matInput formControlName="overview" rows="5"></textarea>
					<mat-error>
						Please enter Overview
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Justification</mat-label>
					<textarea matInput formControlName="justification" rows="5"></textarea>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-6 col-lg-6">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Start Date</mat-label>
					<input matInput [matDatepicker]="pickerStartDate" formControlName="startDateLocal" [max]="getEndDate()" required>
					<mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
					<mat-datepicker #pickerStartDate></mat-datepicker>
					<mat-error>Please enter start date</mat-error>
				</mat-form-field>
			</div>
			<div class="col-6 col-lg-6">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>End Date</mat-label>
					<input matInput [matDatepicker]="pickerEndDate" formControlName="endDateLocal" [min]="getStartDate()" required>
					<mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
					<mat-datepicker #pickerEndDate></mat-datepicker>
					<mat-error>Please enter end date</mat-error>
				</mat-form-field>
			</div>

			<div class="col-6 col-lg-4">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Setting</mat-label>
					<mat-select name="settingTypeInd" formControlName="settingTypeInd">
					  <mat-option  *ngFor="let v of facilitySettings" [value]="v.id">{{v.name}}</mat-option>
					</mat-select>
					<mat-error>Setting is required</mat-error>
				</mat-form-field>
			</div>
			<div class="col-6 col-lg-4">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Space Type</mat-label>
					<mat-select name="spaceTypeInd" formControlName="spaceTypeInd">
					  <mat-option *ngFor="let v of facilitySpaces" [value]="v.id">{{v.name}}</mat-option>
					</mat-select>
					<mat-error>Space Type is required</mat-error>
				</mat-form-field>
			</div>
			<div class="col-6 col-lg-4">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Surface Type</mat-label>
					<mat-select name="surfaceTypeInd" formControlName="surfaceTypeInd">
					  <mat-option  *ngFor="let v of facilitySurfaces" [value]="v.id">{{v.name}}</mat-option>
					</mat-select>
					<mat-error>Surface Type is required</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-6 col-lg-3">
				<mat-form-field class="w-100" appearance="fill" floatLabel="always">
					<mat-label>Estimated Cost</mat-label>
					<input matInput formControlName="estimatedCost">
					<span matTextPrefix>{{currencySymbol}}&nbsp;</span>
					<mat-error>
						Please enter valid Estimated Cost
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-12 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Funding</mat-label>
					<mat-select name="fundingInd" formControlName="fundingInd">
						<mat-option *ngFor="let v of fundings" [value]="v.id">{{v.name}}</mat-option>
					</mat-select>
					<mat-error>Please select funding</mat-error>
				</mat-form-field>
			</div>

			<div class="col-12 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Progress</mat-label>
					<mat-select name="progressInd" formControlName="progressInd">
						<mat-option *ngFor="let v of progress" [value]="v.id">{{v.name}}</mat-option>
					</mat-select>
					<mat-error>Please select progress</mat-error>
				</mat-form-field>
			</div>

			<div class="col-6 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Priority</mat-label>
					<mat-select name="projectPriorityInd" formControlName="projectPriorityInd">
						<mat-option *ngFor="let v of projectPriorities" [value]="v.id">{{v.name}}</mat-option>
					</mat-select>
					<mat-error>Please select project priority</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-6 col-md-6 col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Partner Organisation Name</mat-label>
					<input matInput formControlName="partnerOrganisationName">
					<mat-error>
						Please enter partner organisation name
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-lg-3 col-md-6 col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Visibility</mat-label>
					<mat-select formControlName="visibilityInd">
						<mat-option *ngFor="let v of actionPlanVisibilities" [value]="v.id">{{v.name}}</mat-option>
					</mat-select>
					<mat-error>Please select visibility</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<section style="overflow: hidden;">
					<mat-checkbox formControlName="allowComments">Allow Comments</mat-checkbox>
				</section>
			</div>
		</div>

	</form>
</div>
