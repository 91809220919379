import { Component } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
	selector: 'app-sportseye-card-hero',
	templateUrl: './card-hero.component.html',
	styleUrls: ['./card-hero.component.scss']
})
export class CardHeroComponent extends CardComponent {
	super() { }
}
