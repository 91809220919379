import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from 'src/app/configs/config';
import { Job } from 'src/app/shared/models/job';
import { JobHistory } from 'src/app/shared/models/jobHistory';
import { AuthService } from './auth.service';
import { UnmappedMembershipTypeDto } from 'src/app/shared/models/organisation';
import { AggregatedUnmappedActivityDto } from 'src/app/shared/models/organisation/AggregatedUnmappedActivityDto';

@Injectable({
    providedIn: 'root',
})
export class EtlService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    getJob(jobId: string): Observable<Job> {
        return this.http.get<Job>(Api.getEtlJobUrl.replace('{id}', jobId));
    }
    processJob(jobId: string): Observable<any> {
        return this.http.get<any>(Api.etlJobProcessUrl.replace('{id}', jobId));
    }

    getJobHistoryForOrganisation(orgId: string): Observable<JobHistory[]> {
        return this.http.get<JobHistory[]>(Api.etlFileUploadHistoryUrl.replace('{id}', orgId));
    }

    getJobHistory(jobId: number): Observable<Job> {
        return this.http.get<Job>(Api.etlJobHistory.replace('{id}', jobId.toString()));
    }

    getJobErrors(jobId: number): Observable<Job> {
        return this.http.get<Job>(Api.etlJobErrorsUrl.replace('{id}', jobId.toString()));
    }

    getUnmappedSites(orgId: number): Observable<UnmappedSitesDto> {
        return this.http.get<UnmappedSitesDto>(Api.getUnmappedOrganisationSitesUrl.replace('{id}', orgId.toString()));
    }

    postFileUpload(formData: FormData, organisationId: number, year: number): Observable<HttpEvent<any>> {
        const url = `${Api.etlFileUploadUrl}/${organisationId}/${year}`;
        return this.http.post(url, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    postNIDFileUpload(formData: FormData, organisationId: number): Observable<HttpEvent<any>> {
        const url = `${Api.etlNIDFileUploadUrl}/${organisationId}`;
        return this.http.post(url, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    postOrganisationSitesMapping(sites: SaveOrganisationSiteCmd[]): Observable<any> {
        return this.http.post<any>(Api.postRangeOrgSitesUrl, { sites: sites });
    }

    getUnMappedMemberships(orgId: number): Observable<Array<UnmappedMembershipTypeDto>> {
        return this.http.get<Array<UnmappedMembershipTypeDto>>(
            Api.getUnmappedMembershipTypesUrl.replace('{id}', orgId.toString())
        );
    }

    getUnMappedActivities(orgId: number): Observable<Array<AggregatedUnmappedActivityDto>> {
        return this.http.get<Array<AggregatedUnmappedActivityDto>>(
            Api.getUnmappedActivitiesUrl.replace('{id}', orgId.toString())
        );
    }

    deleteAllJobData(orgId: string): Observable<any[]> {
        return this.http.delete<any>(Api.deleteAllJobDataUrl.replace('{orgId}', orgId));
    }

    retryLastJob(orgId: string): Observable<any[]> {
        return this.http.get<any>(Api.retryLastJobUrl.replace('{orgId}', orgId));
    }
}

/** Represents  */
export interface UnmappedSitesDto {
    totalSites: number;
    highProbabilityMatches: number;
    noMatchesAtAll: number;
    sites: UnmappedSiteDto[];
}

/** Represents an unmapped site for an organisation */
export interface UnmappedSiteDto {
    theirIdentifier: string;
    theirName: string;
    nidSiteId: number;

    /** Operator = 0, Owner = 1, Contributor = 2 */
    typeInd: number;

    createdByJobId: number;
    confidentMatch: boolean;
    totalSuggestions: number;

    suggestions: UnmappedSiteSuggestionDto[];
}

/** Represents a mapping suggestion for an unmapped site */
export interface UnmappedSiteSuggestionDto {
    id: number;
    name: string;
    address: string;
    latitude: number;
    longitude: number;
    rank: number;
}

/** Represents an organisation site mapping */
export interface SaveOrganisationSiteCmd {
    identifier: string;
    name: string;
    typeInd: number;
    createdByJobId: number;
    nidSiteId: number;
    organisationId: number;
}
