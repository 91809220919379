import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

import { ActionPlanService } from 'src/app/core/services/action-plan.service';
import { EnumService } from 'src/app/core/services/enum.service';

import { MessageService } from 'src/app/core/services/message.service';
import { ActionPlan } from 'src/app/shared/models/actionPlan';
import { KeyValuePair } from 'src/app/shared/models/keyvaluepair';
import { NIDRecordEditCloseEvent, NIDSiteModalState } from '../../../nid-modal.component';
import { getCurrencySymbol } from '@angular/common';
import { environment } from 'src/environments/environment';
import BaseComponent from 'src/app/core/base/base-component.component';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-action-plan-edit',
    templateUrl: './action-plan-edit.component.html',
    styleUrls: ['./action-plan-edit.component.scss'],
})
export class NIDActionPlanEditComponent extends BaseComponent implements OnInit {
    @Input() siteData: NIDSiteModalState;
    @Output() editRecordCloseEvent = new EventEmitter<NIDRecordEditCloseEvent>();

    facilitySubTypeId: number = 0;
    isNew: boolean = true;
    pageTitle: string = 'Add New Action Plan';
    pageSubTitle: string = 'Create Action Plan';

    currencySymbol: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private enumService: EnumService,
        private actionPlanService: ActionPlanService,
        private messageService: MessageService
    ) {
        super();
    }

    form = this.formBuilder.group({
        id: [0],
        name: ['', Validators.required],
        typeInd: [0, Validators.required],
        typeOther: [''],
        overview: [''],
        estimatedCost: [''],

        fundingInd: [0],
        progressInd: [0],

        startDateLocal: ['', Validators.required],
        endDateLocal: ['', Validators.required],

        projectPriorityInd: [0],
        partnerOrganisationName: [''],

        visibilityInd: [1, Validators.required],
        allowComments: [false],

        concurrencyStamp: ['71D1FC34-8D18-4774-8612-0E4482959FD2'],
        justification: [''],

        settingTypeInd: ['', Validators.required],
        spaceTypeInd: ['', Validators.required],
        surfaceTypeInd: ['', Validators.required],
    });

    actionPlanTypes: KeyValuePair[];
    fundings: KeyValuePair[];
    progress: KeyValuePair[];
    projectPriorities: KeyValuePair[];
    actionPlanVisibilities: KeyValuePair[];

    facilitySettings: KeyValuePair[] = [];
    facilitySpaces: KeyValuePair[] = [];
    facilitySurfaces: KeyValuePair[] = [];

    ngOnInit(): void {
        this.loadMasterData();

        this.form.get('typeInd').valueChanges.subscribe((change) => {
            if (change === 9) {
                this.form.get('typeOther').setValidators(Validators.required);
            } else {
                this.form.get('typeOther').clearValidators();
            }
            this.form.get('typeOther').updateValueAndValidity();
        });
        this.currencySymbol = getCurrencySymbol(environment.currencyCodeISO4217, 'narrow');
    }

    get f() {
        return this.form.controls;
    }

    setPageTitles(title: string, subTitle: string) {
        this.pageTitle = title;
        this.pageSubTitle = subTitle;
    }

    loadMasterData() {
        this.loading = true;

        //-- Load Enums
        this.enumService.enums
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((enums) => {
                this.actionPlanTypes = enums.ActionPlanType;
                this.progress = enums.Progress;
                this.fundings = enums.Funding;
                this.projectPriorities = enums.ProjectPriority;
                this.actionPlanVisibilities = enums.ActionPlanVisibility;
                this.facilitySettings = enums.NidFacilitySettingInd;
                this.facilitySpaces = enums.NidFacilitySpaceTypeInd;
                this.facilitySurfaces = enums.NidFacilitySurfaceTypeInd;
            });

        if (this.siteData.selectedActionPlanId > 0) {
            this.loadActionPlan(this.siteData.selectedActionPlanId);
        }
    }

    loadActionPlan(id: number) {
        this.loading = true;

        this.actionPlanService
            .getActionPlan(id)
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((data) => {
                this.setPageTitles('Edit ' + data.name, 'Edit an Action Plan');

                // -- Populating Form Values
                Object.keys(this.form.controls).forEach((key) => {
                    this.form.controls[key].setValue(data[key]);
                });
            });
    }

    //Save or Update
    submitForm() {
        this.form.markAllAsTouched();

        if (this.form.invalid) return;

        const actionPlan: ActionPlan = this.form.value;
        //actionPlan.nidFacilitySubTypeId = this.facilitySubTypeId;
        actionPlan.nidSiteId = this.siteData.siteId;
        actionPlan.startDateLocal = moment(this.f.startDateLocal.value).local().format('YYYY-MM-DDT00:00:00');
        actionPlan.endDateLocal = moment(this.f.endDateLocal.value).local().format('YYYY-MM-DDT00:00:00');

        if (this.siteData.selectedActionPlanId > 0) {
            actionPlan.id = this.siteData.selectedActionPlanId;
            this.actionPlanService
                .updateActionPlan(this.siteData.selectedActionPlanId, actionPlan)
                .pipe(takeUntil(this.subscriptionComplete))
                .subscribe((data) => {
                    if (data) {
                        this.messageService.showSuccess('Action Plan has been updated.');
                        this.editRecordCloseEvent.emit({
                            recordId: data,
                            recordWasUpdated: true,
                        });
                    }
                });
        } else {
            this.actionPlanService
                .createActionPlan(actionPlan)
                .pipe(takeUntil(this.subscriptionComplete))
                .subscribe((data) => {
                    if (data) {
                        this.messageService.showSuccess('New Action Plan has been created.');
                        this.editRecordCloseEvent.emit({
                            recordId: data,
                            recordWasUpdated: true,
                        });
                    }
                });
        }
    }

    /** Cancel Button Event Handler */
    cancel() {
        this.editRecordCloseEvent.emit({
            recordId: 0,
            recordWasUpdated: false,
        });
    }

    getEndDate() {
        return this.form.get('endDateLocal').value;
    }

    getStartDate() {
        return this.form.get('startDateLocal').value;
    }
}
