import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'src/app/core/services/message.service';
import { UserDto } from 'src/app/shared/models/userDto';
import { NavigationItem } from 'src/app/shared/models/UserUI';

import { Location } from '@angular/common';
import { Router } from '@angular/router';
import BaseComponent from '../../base/base-component.component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
    fullName: string = '';
    isloggedIn: boolean = false;
    userInfo: UserDto = {} as UserDto;
    activeLink: string;

    //-- Header Menu / Mobile side menu
    topMenu: NavigationItem[] = [
        { icon: 'fmd_good', text: 'MAPS', routeKey: '/maps', type: 'topMenu', description: '' },
    ];

    //-- User Menu (right side menu)
    userMenu: NavigationItem[] = [];

    constructor(
        private messageService: MessageService,
        private location: Location,
        private authService: AuthService,
        private router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.authService.isUserLoggedIn()) {
            this.isloggedIn = true;
            this.userInfo = this.authService.getUserInfo();

            this.fullName = this.userInfo.firstName + ' ' + this.userInfo.lastName;

            this.router.events.pipe(takeUntil(this.subscriptionComplete)).subscribe(() => {
                this.activeLink = this.location.path();
            });
        }

        this.topMenu = this.authService.getTopMenu();
        this.userMenu = this.authService.getUserMenu();

        this.messageService.isLoggedIn$.pipe(takeUntil(this.subscriptionComplete)).subscribe((loggedIn) => {
            this.isloggedIn = loggedIn;
            if (loggedIn) {
                let fname = this.authService.getFullName();
                this.fullName = fname ? fname : '';
            }
        });
    }

    toggleSideMenu() {
        this.messageService.toggleSidnav();
    }
    closeSideMenu() {
        this.messageService.closeSidenav();
    }

    logout() {
        this.authService.logOut();
    }
}
