import { HttpEvent } from '@angular/common/http';
import {
    Component,
    ComponentRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnInit,
    Output,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { ConfirmDialogModel, ConfirmationComponent } from '../../confirmation/confirmation.component';
import { first, takeUntil } from 'rxjs/operators';
import BaseComponent from 'src/app/core/base/base-component.component';

/**
 * Configuration for UploadFileModalComponent
 */
export interface IUploadFileModalComponentConfig {
    acceptedTypes: string[];
    multiSelect: boolean;
    component: Type<any>;
}

/**
 * Data emitted when a file is uploaded
 */
export interface IUploadFileModalComponentSelectionData {
    file: File;
    formValue: any;
}

@Component({
    selector: 'app-upload-file-modal',
    templateUrl: './upload-file-modal.component.html',
    styleUrls: ['./upload-file-modal.component.scss'],
})
export class UploadFileModalComponent extends BaseComponent implements OnInit {
    @Input() uploadRequest$: Subject<Observable<HttpEvent<any>>> = new Subject();

    @Output() uploadStarted = new EventEmitter<IUploadFileModalComponentSelectionData>();

    @HostListener('window:keyup.esc') onEscKeyUp() {
        this.confirmClose();
    }

    @ViewChild('target', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;

    componentRef: ComponentRef<any>;

    allFilesUploaded = true;
    fileUploaded = false;

    constructor(
        private dialogRef: MatDialogRef<UploadFileModalComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public dialogData: IUploadFileModalComponentConfig
    ) {
        super();
    }

    ngOnInit(): void {
        this.dialogRef.disableClose = true;
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.subscriptionComplete))
            .subscribe((_) => {
                this.confirmClose();
            });
        if (this.dialogData.component) {
            this.componentRef = this.vcRef.createComponent(this.dialogData.component);
        }
    }

    confirmClose(): void {
        if (this.allFilesUploaded) {
            this.dialogRef.close(this.fileUploaded);
            return;
        }

        const fileText = this.dialogData.multiSelect
            ? 'Your selected files have not all been uploaded.'
            : 'Your selected file has not been uploaded.';
        const confirmDialogData = new ConfirmDialogModel(
            'Confirm Close',
            fileText + ' Are you sure you want to close?',
            'CLOSE'
        );

        const confirmDialogRef = this.dialog.open(ConfirmationComponent, {
            maxWidth: '400px',
            data: confirmDialogData,
            panelClass: 'seDialog',
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.subscriptionComplete))
            .subscribe((dialogResult) => {
                if (!dialogResult) {
                    return;
                }
                this.dialogRef.close(this.fileUploaded);
            });
    }

    startUpload(file: File) {
        if (this.componentRef) {
            this.componentRef.instance.formValue
                .pipe(takeUntil(this.subscriptionComplete))
                .pipe(first())
                .subscribe((formValue: any) => {
                    if (formValue) {
                        this.uploadStarted.emit({ file: file, formValue: formValue });
                    }
                });
            this.componentRef.instance.submit$.next();
        } else {
            this.uploadStarted.emit({ file: file, formValue: null });
        }
    }
}
