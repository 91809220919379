import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrls: ['./help-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HelpModalComponent {
    @Input() title: string;
    @Input() content: string;
    @Input() vimeoVideoUrl: string;
}
