import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { AuthService } from '../../../core/services/auth.service';
import BaseComponent from 'src/app/core/base/base-component.component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-password-forgot',
    templateUrl: './password-forgot.component.html',
    styleUrls: ['./password-forgot.component.scss'],
})
export class PasswordForgotComponent extends BaseComponent {
    loading: boolean = false;
    isComplete: boolean = false;
    serverErrors: string[] = [];
    form = this.formBuilder.group({
        userName: ['', Validators.email],
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authService: AuthService,
        private errHandlerService: ErrorHandlerService
    ) {
        super();
    }

    get f() {
        return this.form.controls;
    }

    async onSubmit() {
        this.serverErrors = [];

        if (this.form.invalid) {
            this.serverErrors.push('Please input valid email address');
            return;
        }

        this.loading = true;
        this.authService
            .requestResetPassword(this.f.userName.value)
            .pipe(takeUntil(this.subscriptionComplete))
            .subscribe(
                () => {
                    this.loading = false;
                    this.isComplete = true;
                },
                (err) => {
                    this.handleError(err);
                }
            );
    }

    handleError(err: any) {
        this.serverErrors = this.errHandlerService.handleError(err);
    }

    navigate() {
        this.router.navigate(['/login']);
    }
}
