import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-panel',
  templateUrl: './error-panel.component.html',
  styleUrls: ['./error-panel.component.scss']
})
export class ErrorPanelComponent {
  @Input() iconName: string = '';
  @Input() iconOutlined: boolean = true;
  @Input() inputText:string = '';
  @Input() backgroundColor:string = 'red';
  @Input() padding: string = 'low';

   /** Custom Class to added to button */
   bemModifierClasses(): string {
    let result: string = '';

    if (this.backgroundColor.length > 0) result += " seIconTextPanel--" + this.backgroundColor;
    if (this.padding.length > 0) result += " seIconTextPanel--padding" + this.padding;


    return result;
  }

}
