import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-sportseye-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss']
})
export class CardComponent {
	@Input() accent = true;
	@Input() isNew: boolean;
	@Input() date: Date;
	@Input() title: string;
	@Input() body: string;
	@Input() image: string;
	hovered: boolean;
}
