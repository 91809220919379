import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MapService } from 'src/app/core/services/maps/map.service';
import { SiteService } from 'src/app/core/services/site.service';
import { Site } from 'src/app/shared/models/site';
import { MessageService } from 'src/app/core/services/message.service';
import { MapMessaging } from '../../../../../../../core/services/maps/mapMessage';
import { NIDSiteModalState } from '../../../nid-modal.component';
import BaseComponent from 'src/app/core/base/base-component.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { KeyValue } from '@angular/common';

@Component({
    selector: 'app-site-view',
    templateUrl: './site-view.component.html',
    styleUrls: ['./site-view.component.scss'],
})
export class NIDSiteViewComponent extends BaseComponent implements OnInit {
    @Input() siteData: NIDSiteModalState;
    @Output() lastUpdatedDateEvent = new EventEmitter<Date>();

    site: Site = { id: 0 };
    siteDetailsList;
    locationList;
    servicesList;

    constructor(
        private siteService: SiteService,
        private messageService: MessageService,
        private mapService: MapService
    ) {
        super();
    }

    ngOnInit(): void {
        this.loadSite();
    }

    editLocation() {
        if (!this.siteData.userCanEditSite) {
            this.messageService.showError(
                'Please contact the Site Owner/Operator or ActiveXchange Support to request changes.'
            );
            return false;
        }

        this.mapService.sendMessageToMap({
            messageType: MapMessaging.Enums.MapMessageTypeInd.EditSiteLocation,
            messageData: <MapMessaging.MessageDataDTOs.EditSiteLocationMessageDataDTO>{
                id: this.site.id,
                name: this.site.name,
                address: this.site.address,
                latitude: this.site.latitude,
                longitude: this.site.longitude,
                concurrencyStamp: this.site.concurrencyStamp,
            },
        });
    }

    editQuadkey() {
        this.mapService.sendMessageToMap({
            messageType: MapMessaging.Enums.MapMessageTypeInd.EditSiteQuadkey,
            messageData: <MapMessaging.MessageDataDTOs.EditSiteQuadkeyMessageDataDTO>{
                id: this.site.id,
                name: this.site.name,
                address: this.site.address,
                latitude: this.site.latitude,
                longitude: this.site.longitude,
                concurrencyStamp: this.site.concurrencyStamp,
                quadkeys: this.site.quadKeys,
                viewOnly: !this.siteData.userCanEditSite,
            },
        });
    }

    /**
     * Fetch data about a site
     * @param id
     */
    loadSite() {
        this.loading = true;

        this.siteService
            .getSite(this.siteData.siteId)
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((data) => {
                this.site = data;
                this.lastUpdatedDateEvent.emit(data.lastUpdateOn);
                this.siteData.siteName = data.name;
                this.siteData.userCanEditSite = data.canEditSite;
                this.generateTables();
            });
    }

    checkFlag(flag: number, v: number): boolean {
        return (flag & v) > 0;
    }

    getStatusColor(v: number) {
        if (v == 0) return '#11944F';
        else return '#0086b3';
    }

    generateTables() {
        const siteDetailsList: any = {
            Owner: (this.site.ownerName ? this.site.ownerName : '') + ' (' + this.site.ownerTypeIndStr + ')',
            'Operated By':
                (this.site.operatorName ? this.site.operatorName : '') + ' (' + this.site.operatorTypeIndStr + ')',
            'Built In (Approx.)': this.site.approxBuildYear,
            'Last Refurb (Approx.)': this.site.approxLastRefurbYear,
            Weekdays:
                this.site.openingHoursWeekdayOpenStr && this.site.openingHoursWeekdayCloseStr
                    ? `${this.site.openingHoursWeekdayOpenStr} - ${this.site.openingHoursWeekdayCloseStr}`
                    : '-',
            Weekends:
                this.site.openingHoursWeekendOpenStr && this.site.openingHoursWeekendCloseStr
                    ? `${this.site.openingHoursWeekendOpenStr} - ${this.site.openingHoursWeekendCloseStr}`
                    : '-',
            Website: this.site.websiteURL,
            Email: this.site.contactEmail,
            Condition: this.site.conditionIndStr,
            'Condition Rated On': new Date(this.site.conditionRatedDateTimeUTC).toLocaleDateString('en-AU', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            }),
            'Charging Policy': this.site.chargingPolicyIndStr,
            'Average Price': this.site.chargingPolicyPrice ? `$ ${this.site.chargingPolicyPrice}` : '-',
        };
        const locationList: any = {
            Address: this.site.address,
            'Lat / Long': `${this.site.latitude} / ${this.site.longitude}`,
        };
        const servicesList: any = {
            'Changing Rooms': this.site.totalChangingRoooms,
            'Meeting Rooms': this.site.totalMeetingFunctionRooms,
            'Parking Spaces': this.site.totalParkingSpaces,
            'Food / Beverage': this.site.foodAndBeverageServiceIndStr,
        };
        [siteDetailsList, locationList, servicesList].forEach((list) => {
            // Filter out empty values
            Object.keys(list).forEach((key) => {
                if (list[key]) {
                    return;
                }
                delete list[key];
            });
            // Add extra cell if odd number
            if (Object.keys(list).length % 2) {
                list[''] = '';
            }
        });
        this.siteDetailsList = siteDetailsList;
        this.locationList = locationList;
        this.servicesList = servicesList;
    }

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    };
}
