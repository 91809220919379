import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-loading-indicator',
	templateUrl: './loading-indicator.component.html',
	styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {
	@Input() text: string = 'Please Wait';
}
