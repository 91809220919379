import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmationComponent } from '../confirmation/confirmation.component';
import BaseComponent from 'src/app/core/base/base-component.component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-edit-list',
    templateUrl: './edit-list.component.html',
    styleUrls: ['./edit-list.component.scss'],
})
export class EditListComponent extends BaseComponent implements OnInit, AfterViewInit {
    @Input() titleText: string;
    @Input() subtitle: string;
    @Input() items: any[];
    @Input() itemType: string;
    @Input() email: boolean;
    @Input() required: boolean;
    @Input() deletePrompt: string;

    @Output() itemsChanged = new EventEmitter();
    @Output() validationFailed = new EventEmitter<ValidationErrors>();

    @ViewChildren('itemInput')
    private itemInputs: QueryList<ElementRef<HTMLInputElement>>;

    adding: boolean = false;
    formGroup: FormGroup;
    validationErrors: ValidationErrors = {};

    get itemControlArray(): FormArray {
        return this.formGroup.get('itemControls') as FormArray;
    }

    get newItemControl(): AbstractControl {
        return this.formGroup.get('newItem');
    }

    constructor(private formBuilder: FormBuilder, public dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        const validators = [];
        if (this.required) {
            validators.push(Validators.required);
        }
        if (this.email) {
            validators.push(Validators.email);
        }
        this.formGroup = this.formBuilder.group({
            itemControls: this.formBuilder.array([]),
            newItem: ['', validators],
        });

        this.items.forEach((item) => {
            this.itemControlArray.push(new FormControl(item.name, validators));
        });
    }

    ngAfterViewInit(): void {
        this.itemInputs.changes.pipe(takeUntil(this.subscriptionComplete)).subscribe(() => {
            if (this.itemInputs.length > 0) {
                this.itemInputs.first.nativeElement.focus();
            }
        });
    }

    addItem(): void {
        this.cancelEdit();
        this.adding = true;
    }

    editItem(item: any): void {
        this.cancelEdit();
        item.edit = true;
    }

    confirmRemoveItem(item: any): void {
        if (!this.deletePrompt) {
            this.removeItem(item);
            return;
        }
        const dialogData = new ConfirmDialogModel('Confirm Delete', this.deletePrompt, 'DELETE');

        const dialogRef = this.dialog.open(ConfirmationComponent, {
            maxWidth: '400px',
            data: dialogData,
            panelClass: 'seDialog',
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.subscriptionComplete))
            .subscribe((dialogResult) => {
                if (!dialogResult) {
                    return;
                }
                this.removeItem(item);
            });
    }

    removeItem(item: any): void {
        this.cancelEdit();
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
        this.itemControlArray.removeAt(index);
        this.itemsChanged.emit();
    }

    updateName(item: any): void {
        if (!item) {
            this.newItemControl.updateValueAndValidity();
            if (!this.newItemControl.valid) {
                this.validationFailed.emit(this.newItemControl.errors);
                this.validationErrors = this.newItemControl.errors;
                return;
            }
            this.itemControlArray.push(new FormControl(this.newItemControl.value));
            this.items.push({ name: this.newItemControl.value, edit: false });
        } else {
            const control = this.itemControlArray.controls[this.items.indexOf(item)];
            control.updateValueAndValidity();
            if (!control.valid) {
                this.validationFailed.emit(control.errors);
                this.validationErrors = control.errors;
                return;
            }
            item.name = control.value;
        }
        this.itemsChanged.emit();
        this.cancelEdit();
    }

    cancelAdd(): void {
        this.adding = false;
        this.newItemControl.setValue('');
        this.newItemControl.markAsUntouched();
        this.newItemControl.markAsPristine();
    }

    cancelEdit(): void {
        this.items.forEach((x) => (x.edit = false));
        this.validationErrors = {};
        if (this.adding) {
            this.cancelAdd();
        }
    }
}
