import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit, NgZone, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActionPlan } from 'src/app/shared/models/actionPlan';
import { ActionPlanService } from 'src/app/core/services/action-plan.service';
import { MessageService } from 'src/app/core/services/message.service';
import { AuthService } from 'src/app/core/services/auth.service';
import {
    ConfirmationComponent,
    ConfirmDialogModel,
} from 'src/app/shared/components/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { NIDSiteModalState } from '../../../nid-modal.component';
import BaseComponent from 'src/app/core/base/base-component.component';

@Component({
    selector: 'app-action-plan-view',
    templateUrl: './action-plan-view.component.html',
    styleUrls: ['./action-plan-view.component.scss'],
})
export class NIDActionPlanViewComponent extends BaseComponent implements OnInit {
    @Input() siteData: NIDSiteModalState;

    actionPlans: ActionPlan[] = [];
    currentActionPlan: ActionPlan = { id: 0 };
    actionPlanComments = [];
    comments: string = '';
    dataSource = new MatTableDataSource<ActionPlan>();
    displayedColumns: string[] = ['name'];
    userName: string = '';
    total: number = 0;
    innerWidth: any = 0;
    canEditSite: boolean = false;

    constructor(
        private actionPlanService: ActionPlanService,
        private authService: AuthService,
        private messageService: MessageService,
        private _ngZone: NgZone,
        public dialog: MatDialog
    ) {
        super();
    }

    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    triggerResize() {
        // Wait for changes to be applied, then trigger textarea resize.
        this._ngZone.onStable
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(take(1))
            .subscribe(() => this.autosize.resizeToFitContent(true));
    }

    ngOnInit(): void {
        if (this.siteData.siteId > 0) this.loadSiteActionPlans();

        this.userName = this.authService.getUserName();
    }

    /**
     * Load action plans for site
     * NOTE:  THIS NEEDS TO BE RE-WRITTEN.  Tom Allan / Muhammad are retrieving ALL Action Plans from the backend
     * and are filtering this locally on the client.  This sends potentially sensitive information to the browser
     * about other organisations action plans
     * @param siteId
     */
    loadSiteActionPlans() {
        this.loading = true;

        this.actionPlanService
            .getSiteActionPlans(this.siteData.siteId)
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((data: ActionPlan[]) => {
                if (data) {
                    this.actionPlans = data;

                    this.dataSource.data = data;
                    if (this.actionPlans.length > 0) {
                        this.showDetail(this.actionPlans[0]);
                    }
                }
            });
    }

    /** Show Action Plan Click Event Handler */
    showDetail(actionPlan: ActionPlan) {
        this.siteData.selectedActionPlanId = actionPlan.id;
        this.currentActionPlan = this.actionPlans.find((x) => x.id == actionPlan.id);
        this.loadActionPlanComments(this.currentActionPlan.id);
    }

    /** Add New Action Plan Click Event Handler */
    addNew(): void {
        if (!this.siteData.userCanEditSite) {
            this.messageService.showError(
                'Please contact the Site Owner/Operator or ActiveXchange Support to request changes.'
            );
            return;
        }
        this.siteData.selectedActionPlanId = 0;
        this.siteData.editMode = true;
    }

    /**
     * Add Comment Click Event Handler
     * NOTE:  THIS NEEDS TO BE RE-WRITTEN.  Tom Allan / Muhammad are setting the user id on the client
     * before sending this to the backend.  The backend should be the one assigning the user ID based
     * on the logged in user
     */
    addComment() {
        let c = {
            id: 0,
            comments: this.comments,
            userId: this.userName,
            dateTimeUTC: new Date(),
            nidActionPlanId: this.currentActionPlan.id,
        };

        this.loading = true;

        this.actionPlanService
            .addComment(c)
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(() => {
                this.comments = '';
                this.loadActionPlanComments(this.currentActionPlan.id);
            });
    }

    /** Load Comments for current action plan */
    loadActionPlanComments(planId: number) {
        this.loading = true;

        this.actionPlanService
            .getActionPlanComments(planId)
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((data) => {
                this.actionPlanComments = data.sort((a: any, b: any) => b.dateTimeUTC - a.dateTimeUTC);
            });
    }

    /** Delete Comment Click Event Handler */
    removeComment(commId: number) {
        const message = `Are you sure you want to delete this comment?`;
        const dialogData = new ConfirmDialogModel('Confirm Delete', message, 'DELETE');

        const dialogRef = this.dialog.open(ConfirmationComponent, {
            maxWidth: '400px',
            data: dialogData,
            panelClass: 'seDialog',
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.subscriptionComplete))
            .subscribe((dialogResult) => {
                if (!dialogResult) {
                    return;
                }

                this.actionPlanService
                    .deleteActionPlanComment(commId)
                    .pipe(takeUntil(this.subscriptionComplete))
                    .subscribe(() => {
                        this.messageService.showSuccess('Comment has been deleted');
                        this.actionPlanComments = this.actionPlanComments.filter((x) => x.id != commId);
                    });
            });
    }

    /** Remove Action Plan Click Event Handler */
    removeActionPlan(actionPlan: ActionPlan): void {
        if (!this.siteData.userCanEditSite) {
            this.messageService.showError(
                'Please contact the Site Owner/Operator or ActiveXchange Support to request changes.'
            );
            return;
        }
        const message = `Are you sure you want to delete this action plan? (${actionPlan.typeIndStr} - ${actionPlan.facilityTypeName})`;

        const dialogData = new ConfirmDialogModel('Confirm Delete', message, 'DELETE');

        const dialogRef = this.dialog.open(ConfirmationComponent, {
            maxWidth: '400px',
            data: dialogData,
            panelClass: 'seDialog',
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.subscriptionComplete))
            .subscribe((dialogResult) => {
                if (!dialogResult) {
                    return;
                }
                this.actionPlanService.deleteActionPlan(actionPlan.id).subscribe(() => {
                    this.messageService.showSuccess('Action has been deleted');
                    this.loadSiteActionPlans();
                });
            });
    }
}
