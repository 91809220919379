<div class="button-bar">
	<app-icon-button
		icon="cancel"
		text="CANCEL"
		size="small"
		(buttonClicked)="cancel()"
		color="grey"></app-icon-button>
	<app-icon-button icon="check_circle" text="SAVE" size="small" (buttonClicked)="submitForm()"></app-icon-button>
</div>

<div style="padding: 15px">
	<form [formGroup]="form" (ngSubmit)="submitForm()" class="container-fluid">
		<section *ngIf="serverErrors.length > 0" class="error-panel-margin">
			<app-error-panel class="w-100" iconName="error" [inputText]="serverErrors[0]" padding="high"></app-error-panel>
		</section>

		<!-- Name/Status Row-->
		<div class="row">
			<div class="col-12 col-lg-6">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Facility Name</mat-label>
					<input matInput formControlName="name" required />
					<mat-error> Please enter facility name </mat-error>
				</mat-form-field>
			</div>

			<div class="col-12 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Status</mat-label>
					<mat-select name="statusInd" formControlName="statusInd">
						<mat-option *ngFor="let s of status" [value]="s.id">{{ s.name }}</mat-option>
					</mat-select>
					<mat-error>Please select facility status</mat-error>
				</mat-form-field>
			</div>

			<div class="col-12 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Condition</mat-label>
					<mat-select name="conditionInd" formControlName="primaryBuiltConditionInd">
						<mat-option *ngFor="let v of conditions" [value]="v.id">{{ v.name }}</mat-option>
					</mat-select>
					<mat-error>Please select site condition</mat-error>
				</mat-form-field>
			</div>
		</div>

		<b>Facility Details</b>
		<mat-divider></mat-divider>
		<div class="row">
			<div class="col-12 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Setting</mat-label>
					<mat-select name="settingTypeInd" formControlName="settingTypeInd">
						<mat-option *ngFor="let v of facilitySettings" [value]="v.id">{{ v.name }}</mat-option>
					</mat-select>
					<mat-error>Setting is required</mat-error>
				</mat-form-field>
			</div>
			<div class="col-12 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Space Type</mat-label>
					<mat-select
						name="spaceTypeInd"
						formControlName="spaceTypeInd"
						(selectionChange)="updateSpaceTypeMetadata($event.value)">
						<mat-option *ngFor="let v of facilitySpaces" [value]="v.id">{{ v.name }}</mat-option>
					</mat-select>
					<mat-error>Space Type is required</mat-error>
				</mat-form-field>
			</div>
			<div class="col-12 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Surface Type</mat-label>
					<mat-select name="surfaceTypeInd" formControlName="surfaceTypeInd">
						<mat-option *ngFor="let v of facilitySurfaces" [value]="v.id">{{ v.name }}</mat-option>
					</mat-select>
					<mat-error>Surface Type is required</mat-error>
				</mat-form-field>
			</div>
		</div>

		<section *ngIf="facilitySpaceTypeMetadataForSelectedSpaceType?.length > 0">
			<b>Additional information about this space type</b>
			<mat-divider></mat-divider>
			<div class="row">
				<div class="col-6 col-md-4 col-lg-3" *ngFor="let question of facilitySpaceTypeMetadataForSelectedSpaceType">
					<!-- <pre>{{question | json}}</pre> -->
					<mat-form-field class="w-100" appearance="fill" *ngIf="question.typeInd == 1">
						<mat-label>{{ question.question }}</mat-label>
						<input
							matInput
							type="number"
							[valueAsNumber]="question.decimalAnswer"
							(keyup)="setMetadataNumericAnswer(question, $event.target)" />
						<span matTextSuffix>{{ question.suffix }}</span>
						<mat-hint>{{ question.helpText }}</mat-hint>
					</mat-form-field>

					<mat-form-field class="w-100" appearance="fill" *ngIf="question.typeInd == 2">
						<mat-label>{{ question.question }}</mat-label>
						<mat-select
							(selectionChange)="setMetadataDropDownAnswer(question, $event.value)"
							[value]="question.dropDownAnswer">
							<mat-select-trigger>{{ getLabelForMetadataDropdown(question) }}</mat-select-trigger>
							<mat-option *ngFor="let dd of question.dropDownOptions" [value]="dd.id" style="height: 4em">
								<p class="lh-1 m-0">{{ dd.optionText }}</p>
								<p class="lh-1 mat-small m-0 text-grey">{{ dd.inlineDescription }}</p>
							</mat-option>
						</mat-select>
						<mat-hint>{{ question.helpText }}</mat-hint>
					</mat-form-field>
					<mat-icon
						class="ddHelpIcon"
						*ngIf="shouldDisplayHelpSymbolForDropDown(question)"
						[matTooltip]="getHelpTextForMetadataDropdown(question)">
						info
					</mat-icon>
				</div>
			</div>
		</section>

		<b>Other Details</b>
		<mat-divider></mat-divider>
		<div class="row">
			<div class="col-6 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Built in (Approx)</mat-label>
					<input matInput type="number" formControlName="approxBuildYear" />
					<mat-error> Please enter valid build year </mat-error>
				</mat-form-field>
			</div>
			<div class="col-6 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Last Refurbished (Approx)</mat-label>
					<input matInput type="number" formControlName="approxLastRefurbYear" />
					<mat-error> Please enter valid last refurbish year </mat-error>
				</mat-form-field>
			</div>
			<div class="col-6 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Remaining Lifetime (Approx. Years)</mat-label>
					<input matInput type="number" formControlName="remainingLifetime" />
					<mat-error> Please enter the estimated remaining lifetime (years) </mat-error>
				</mat-form-field>
			</div>
			<div class="col-6 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Usage Level</mat-label>
					<mat-select name="usageLevelInd" formControlName="usageLevelInd">
						<mat-option *ngFor="let v of usageLevels" [value]="v.id">{{ v.name }}</mat-option>
					</mat-select>
					<mat-error>Please select usage level</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-6 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Total Changing Rooms (e.g. 4)</mat-label>
					<input matInput type="number" formControlName="totalDedicatedChangingRooms" />
				</mat-form-field>
			</div>
			<div class="col-6 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Total Spectator Seating Capacity</mat-label>
					<input matInput type="number" formControlName="totalSpectatorSeatingCapacity" />
				</mat-form-field>
			</div>
			<div class="col-6 col-lg-3">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Seating Condition</mat-label>
					<mat-select name="spectatorSeatingConditionInd" formControlName="spectatorSeatingConditionInd">
						<mat-option *ngFor="let v of conditions" [value]="v.id">{{ v.name }}</mat-option>
					</mat-select>
					<mat-error>Please select seating condition</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-6 col-lg-3">
				<mat-checkbox formControlName="hasFloodLighting">Flood Lighting Available</mat-checkbox>
			</div>

			<div class="col-6 col-lg-3">
				<mat-form-field *ngIf="f.hasFloodLighting.value" class="w-100" appearance="fill">
					<mat-label>Lighting Lux Level</mat-label>
					<input matInput formControlName="lightingLuxLevel" />
					<mat-error>Please enter lux level</mat-error>
				</mat-form-field>
			</div>
		</div>

		<b>Monthly Facility Availability</b>
		<button
			mat-raised-button
			color="primary"
			type="button"
			style="float: right"
			*ngIf="f.usageMonthFlag.value != 4095"
			(click)="onFlagChange($event, 'usageMonthFlag', 0)">
			Select All Months
		</button>
		<button
			mat-raised-button
			color="warn"
			type="button"
			style="float: right"
			*ngIf="f.usageMonthFlag.value == 4095"
			(click)="onFlagChange($event, 'usageMonthFlag', -1)">
			Remove All Months
		</button>
		<mat-divider></mat-divider>
		<div class="row mb-2">
			<div class="col-12">
				<div class="d-flex flex-wrap column-gap-3 ">
					<div *ngFor="let tpt of usageMonthFlags">
						<mat-checkbox
							[value]="tpt.id.toString()"
							[checked]="checkFlag(f.usageMonthFlag.value, tpt.id)"
							(change)="onFlagChange($event, 'usageMonthFlag', tpt.id)">
							{{ tpt.name }}
						</mat-checkbox>
					</div>
				</div>
			</div>
		</div>

		<b>Additional comments/notes about this facility</b>
		<mat-divider></mat-divider>
		<div class="row">
			<div class="col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Comments/Notes</mat-label>
					<input matInput formControlName="comments" />
				</mat-form-field>
			</div>
		</div>
	</form>
</div>
